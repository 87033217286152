/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconLesson = (props) => (
  <SVG
    width="25"
    height="29"
    viewBox="0 0 25 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.00004 0.666672C1.52004 0.666672 0.333374 1.85334 0.333374 3.33334V24.6667C0.333374 25.3739 0.614325 26.0522 1.11442 26.5523C1.61452 27.0524 2.2928 27.3333 3.00004 27.3333H8.33337V24.7867L11.12 22H3.00004V19.3333H13.7867L16.4534 16.6667H3.00004V14H19.12L21.6667 11.4533V8.66667L13.6667 0.666672H3.00004ZM12.3334 2.66667L19.6667 10H12.3334V2.66667ZM21.8667 15.3333C21.6667 15.3333 21.48 15.4 21.3334 15.5467L19.9734 16.9067L22.76 19.68L24.12 18.3333C24.4 18.04 24.4 17.56 24.12 17.28L22.3867 15.5467C22.24 15.4 22.0534 15.3333 21.8667 15.3333ZM19.1867 17.6933L11 25.8933V28.6667H13.7734L21.9734 20.4667L19.1867 17.6933Z"
      fill="black"
    />
  </SVG>
)

export default withIconStyles(IconLesson)
