/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconPeople = (props) => (
  <SVG
    width="32"
    height="20"
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 0.333328C17.2377 0.333328 18.4247 0.824994 19.2998 1.70016C20.175 2.57533 20.6667 3.76232 20.6667 4.99999C20.6667 6.23767 20.175 7.42466 19.2998 8.29983C18.4247 9.175 17.2377 9.66666 16 9.66666C14.7623 9.66666 13.5753 9.175 12.7002 8.29983C11.825 7.42466 11.3333 6.23767 11.3333 4.99999C11.3333 3.76232 11.825 2.57533 12.7002 1.70016C13.5753 0.824994 14.7623 0.333328 16 0.333328ZM6.66667 3.66666C7.41333 3.66666 8.10667 3.86666 8.70667 4.22666C8.50667 6.13333 9.06667 8.02666 10.2133 9.50666C9.54667 10.7867 8.21333 11.6667 6.66667 11.6667C5.6058 11.6667 4.58839 11.2452 3.83824 10.4951C3.08809 9.74494 2.66667 8.72753 2.66667 7.66666C2.66667 6.6058 3.08809 5.58838 3.83824 4.83823C4.58839 4.08809 5.6058 3.66666 6.66667 3.66666ZM25.3333 3.66666C26.3942 3.66666 27.4116 4.08809 28.1618 4.83823C28.9119 5.58838 29.3333 6.6058 29.3333 7.66666C29.3333 8.72753 28.9119 9.74494 28.1618 10.4951C27.4116 11.2452 26.3942 11.6667 25.3333 11.6667C23.7867 11.6667 22.4533 10.7867 21.7867 9.50666C22.9333 8.02666 23.4933 6.13333 23.2933 4.22666C23.8933 3.86666 24.5867 3.66666 25.3333 3.66666ZM7.33333 17.3333C7.33333 14.5733 11.2133 12.3333 16 12.3333C20.7867 12.3333 24.6667 14.5733 24.6667 17.3333V19.6667H7.33333V17.3333ZM0 19.6667V17.6667C0 15.8133 2.52 14.2533 5.93333 13.8C5.14667 14.7067 4.66667 15.96 4.66667 17.3333V19.6667H0ZM32 19.6667H27.3333V17.3333C27.3333 15.96 26.8533 14.7067 26.0667 13.8C29.48 14.2533 32 15.8133 32 17.6667V19.6667Z"
      fill="black"
    />
  </SVG>
)

export default withIconStyles(IconPeople)
