import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { notification } from '@edulastic/common'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { KeyBy } from 'lodash'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { testItemsApi } from '@edulastic/api'
import { evaluateItem } from './src/utils/evalution'
import { addItemEvaluationAction } from './src/actions/testItem'
import { changePreviewAction, changeViewAction } from './src/actions/view'
import TestItemPreview from '../assessment/components/TestItemPreview'
import { answersByQId } from '../assessment/selectors/test'

const ItemPlayer = ({
  match,
  answers,
  addEvaluation,
  changePreview,
  evaluation,
  view,
}) => {
  const [testItem, setTestItem] = useState(null)

  useEffect(() => {
    const { id: itemId } = match.params
    testItemsApi
      .getByV1Id(itemId)
      .then(setTestItem)
      .catch(() => notification({ messageKey: 'invalidTestItem' }))
  }, [match.params])

  const evaluate = async () => {
    try {
      const { questions = [] } = testItem.data
      changePreview('check')
      const answersByQids = answersByQId(answers, testItem._id)
      const { evaluation: evals, score, maxScore } = await evaluateItem(
        answersByQids,
        KeyBy(questions, 'id'),
        testItem.itemLevelScoring,
        undefined,
        testItem.itemLevelScore,
        testItem._id
      )
      addEvaluation(evals)
      notification({
        type: 'success',
        msg: `score: ${+score.toFixed(2)}/${maxScore}`,
      })
    } catch (e) {
      console.log(e)
      notification({ messageKey: 'evaluationFailed' })
    }
  }

  const clear = () => {
    changePreview('clear')
  }

  if (!testItem) {
    return <div> Loading... </div>
  }

  const { questions = [], resources = [] } = testItem.data
  const allItems = KeyBy([...questions, ...resources], 'id')

  return (
    <div>
      <TestItemPreview
        cols={testItem.rows}
        questions={allItems}
        preview={view}
        evaluation={evaluation}
        showCollapseBtn
      />
      <Button onClick={evaluate}> Check Answer </Button>
      <Button onClick={clear}> Clear </Button>
    </div>
  )
}

ItemPlayer.propTypes = {
  match: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  addEvaluation: PropTypes.func.isRequired,
  changePreview: PropTypes.func.isRequired,
  evaluation: PropTypes.object.isRequired,
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      answers: state.answers,
      evaluation: state.evaluation,
      view: state.view.preview,
    }),
    {
      addEvaluation: addItemEvaluationAction,
      changePreview: changePreviewAction,
      changeView: changeViewAction,
    }
  )
)(ItemPlayer)
